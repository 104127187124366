import { createApp } from 'vue'

import router from "./router.js";
import store from "./store/index.js";
import App from './App.vue'

import PrimeVue from 'primevue/config';
import Button from "primevue/button";
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row'; 
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Password from 'primevue/password';
import InputNumber from 'primevue/inputnumber';
import Divider from 'primevue/divider';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Image from 'primevue/image';
import Fieldset from 'primevue/fieldset';
import Message from 'primevue/message';                  // optional

import "primevue/resources/primevue.min.css";
//import "primevue/resources/themes/md-light-deeppurple/theme.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';
import "/node_modules/primeflex/primeflex.css";

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import { SidebarMenu } from 'vue-sidebar-menu'
import VueApexCharts from "vue3-apexcharts";

import '@fortawesome/fontawesome-free/css/all.css'

const app = createApp(App);

app.use(router);
app.use(store);

app.component('sb-menu', SidebarMenu);
app.component('pv-button', Button);
app.component('pv-card', Card);
app.component('pv-datatable', DataTable);
app.component('pv-column', Column);
app.component('pv-columngroup', ColumnGroup);
app.component('pv-row', Row);
app.component('pv-toolbar', Toolbar);
app.component('pv-dialog', Dialog);
app.component('pv-inputtext', InputText);
app.component('pv-textarea', Textarea);
app.component('pv-dropdown', Dropdown);
app.component('pv-tag', Tag);
app.component('pv-toast', Toast);
app.component('pv-password', Password);
app.component('pv-inputnumber', InputNumber);
app.component('pv-divider', Divider);
app.component('pv-tabview', TabView);
app.component('pv-tabpanel', TabPanel);
app.component('pv-accordion', Accordion);
app.component('pv-accordiontab', AccordionTab);
app.component('pv-image', Image);
app.component('pv-fieldset', Fieldset);
app.component('pv-message', Message);


app.use(PrimeVue);
app.use(ToastService);
app.use(VueSidebarMenu);

app.use(VueApexCharts);
app.component("apex-chart", VueApexCharts);

app.mount("#app");
