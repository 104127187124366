<template>
  <div class="body-content">
    <pv-toast />

    <div class="card">
      <pv-toolbar class="mb-4">
        <template #start>
          <pv-button
            label="New"
            icon="pi pi-plus"
            severity="success"
            class="mr-2"
            @click="openNew"
          />
          <pv-button
            label="Delete"
            icon="pi pi-trash"
            severity="danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedRecord || !selectedRecord.length"
          />
        </template>

        <template #end>
          <pv-button
            label="Export"
            icon="pi pi-upload"
            severity="help"
            @click="exportCSV($event)"
          />
        </template>
      </pv-toolbar>
    </div>

    <pv-datatable
      ref="dt"
      :value="records"
      v-model:selection="selectedRecord"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
    >
      <template #header>
        <div
          class="flex flex-wrap gap-2 align-items-center justify-content-between"
        >
          <h4 class="m-0">Manage Records</h4>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <pv-inputtext
              v-model="filters['global'].value"
              placeholder="Search..."
            />
          </span>
        </div>
      </template>

      <pv-column
        field="define_sub_metric_defination_link_ecosystem_desc"
        header="Ecosystem"
        sortable
        style="min-width: 12rem"
      ></pv-column>
      <pv-column
        field="define_sub_metric_defination_link_metric_desc"
        header="Metric"
        sortable
        style="min-width: 16rem"
      ></pv-column>
      <pv-column
        field="define_sub_metric_defination_desc"
        header="Defination"
        sortable
        style="min-width: 10rem"
      ></pv-column>
      <pv-column
        field="define_sub_metric_defination_rationale"
        header="Rationale"
        style="min-width: 10rem"
      ></pv-column>
      <pv-column :exportable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <pv-button
            icon="pi pi-pencil"
            outlined
            rounded
            class="mr-2"
            @click="editRecord(slotProps.data)"
          />
          <pv-button
            icon="pi pi-trash"
            outlined
            rounded
            severity="danger"
            @click="confirmDeleteRecord(slotProps.data)"
          />
        </template>
      </pv-column>
    </pv-datatable>

    <pv-dialog
      v-model:visible="recordDialog"
      :style="{ width: '450px' }"
      header="Record Details"
      :modal="true"
      class="p-fluid"
    >
      <div class="field">
        <label for="ecosystemId" class="mb-3">Eco Systems</label>
        <pv-dropdown
          id="ecosystemId"
          v-model="record.define_sub_metric_defination_link_ecosystem_id"
          :options="econtypesRecords"
          optionLabel="option_econ_type_desc"
          optionValue="id"
          placeholder="Select an Ecosystem"
        >
        </pv-dropdown>
      </div>

      <div class="field">
        <label for="metricId" class="mb-3">Metrics</label>
        <pv-dropdown
          id="metricId"
          v-model="record.define_sub_metric_defination_link_metric_id"
          :options="metricdefsRecords"
          optionLabel="option_metric_defination_desc"
          optionValue="id"
          placeholder="Select a Metric"
        >
        </pv-dropdown>
      </div>

      <div class="field">
        <label for="name">Sub Metric</label>
        <pv-inputtext
          id="name"
          v-model.trim="record.define_sub_metric_defination_desc"
          required="true"
          autofocus
          :class="{
            'p-invalid': submitted && !record.define_sub_metric_defination_desc,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && !record.define_sub_metric_defination_desc"
          >Sub Metric is required.</small
        >
      </div>
      <div class="field">
        <label for="description">Metric Rationale</label>
        <pv-textarea
          id="description"
          v-model="record.define_sub_metric_defination_rationale"
          required="true"
          rows="3"
          cols="20"
        />
      </div>

      <template #footer>
        <pv-button label="Cancel" icon="pi pi-times" text @click="hideDialog" />
        <pv-button label="Save" icon="pi pi-check" text @click="saveRecord" />
      </template>
    </pv-dialog>

    <pv-dialog
      v-model:visible="deleteRecordDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="record"
          >Are you sure you want to delete <b>{{ record.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <pv-button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteRecordDialog = false"
        />
        <pv-button label="Yes" icon="pi pi-check" text @click="deleteRecord" />
      </template>
    </pv-dialog>

    <pv-dialog
      v-model:visible="deleteRecordsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="record"
          >Are you sure you want to delete the selected records?</span
        >
      </div>
      <template #footer>
        <pv-button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteRecordsDialog = false"
        />
        <pv-button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </pv-dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      records: null,
      recordDialog: false,
      deleteRecordDialog: false,
      deleteRecordsDialog: false,
      record: {},
      selectedRecord: null,
      filters: {},
      submitted: false,
      econtypesRecords: [],
      metricdefsRecords: [],
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.fetchEcosystems().then((data) => {
      this.records = data;
    });

    this.fetchMetricsDefs().then((data) => {
      //console.log(data);
      this.metricdefsRecords = data;
    });

    this.fetchEcoTypes().then((data) => {
      //console.log(data);
      this.econtypesRecords = data;
    });
  },
  methods: {
    async fetchEcosystems() {
      /*

      this.isLoading = true;

      let getAuth = this.$store.getters["auth/prepAuthHeaders"](
        "fetch_ecosystems"
      );

      const bodyData = { ...getAuth };

      */

      const bodyData = { action_type: "fetch_ecometrics" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchMetricsDefs() {
      /*

      this.isLoading = true;

      let getAuth = this.$store.getters["auth/prepAuthHeaders"](
        "fetch_ecosystems"
      );

      const bodyData = { ...getAuth };

      */

      const bodyData = { action_type: "fetch_option_metricdefs" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchEcoTypes() {
      /*
      this.isLoading = true;

      let getAuth = this.$store.getters["auth/prepAuthHeaders"](
        "fetch_ecosystems"
        );

        const bodyData = { ...getAuth };
      */

      const bodyData = { action_type: "fetch_option_ecotypes" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.record = {};
      this.submitted = false;
      this.recordDialog = true;
    },
    hideDialog() {
      this.recordDialog = false;
      this.submitted = false;
    },
    async saveRecord() {
      this.submitted = true;

      /*

      this.isLoading = true;

      let getAuth = this.$store.getters["auth/prepAuthHeaders"](
        "fetch_ecosystems"
      );

      const bodyData = { ...getAuth };

      */

      let bodyData = { action_type: "save_ecometrics" };

      bodyData = { ...bodyData, ...this.record };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();

        this.recordDialog = false;
        this.record = {};

        this.fetchEcosystems().then((data) => {
          this.records = data;
        });

        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Record has been Saved/Updated",
          life: 3000,
        });

        return data;
      } catch (err) {
        console.log(err);
        this.recordDialog = false;
        this.record = {};

        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "An error has occured. Please try again later.",
          life: 3000,
        });

        return (this.error = "Failed to load, try later.");
      }
    },
    editRecord(record) {
      this.record = { ...record };
      this.recordDialog = true;
    },
    confirmDeleteRecord(record) {
      this.record = record;
      this.deleteRecordDialog = true;
    },
    deleteRecord() {
      /* this.records = this.records.filter((val) => val.id !== this.record.id);      
      this.record = {}; */

      this.deleteRecordDialog = false;
      this.$toast.add({
        severity: "warn",
        summary: "Disabled",
        detail: "This action has been disabled",
        life: 3000,
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.records.length; i++) {
        if (this.records[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteRecordsDialog = true;
    },
    deleteSelectedRecords() {
      this.records = this.records.filter(
        (val) => !this.selectedRecord.includes(val)
      );
      this.deleteRecordsDialog = false;
      this.selectedRecord = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Records Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
