<template>
  <div class="body-content">
    <pv-toolbar class="mb-4">
      <template #start>
        <pv-dropdown
          id="assetId"
          class="use-full-width"
          v-model="selectedRecord"
          :options="records"
          optionLabel="define_assets_desc"
          optionValue="uuid"
          filter
          placeholder="Select an Asset"
          @change="assetSelected"
        >
        </pv-dropdown>
      </template>
      <template #end> </template>
    </pv-toolbar>
    <div class="grid">
      <div class="col-6">
        <div class="grid">
          <div
            class="col-12 card flex justify-content-center bg-contain overflow-hidden"
          >
            <div
              class="p-3 border-round-sm content-card text-gray-600 bg-cover"
            >
              <pv-image
                :src="record.define_assets_image_url"
                alt="No Image"
                width="450"
                preview
              />
            </div>
          </div>
          <div class="col-12">
            <pv-fieldset legend="Description">
              <p>
                {{ record.define_assets_info }}
              </p>
            </pv-fieldset>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <div class="col-12">
            <div class="flex justify-content-center">
              <div
                class="flex-initial flex align-items-center justify-content-center bg-green-400 font-bold m-2 px-5 py-3 border-round"
              >
                <p class="text-white text-xl line-height-3">
                  Net Asset Value<br /><b>$600M</b>
                </p>
              </div>
              <div
                class="flex-initial flex align-items-center justify-content-center bg-orange-400 font-bold m-2 px-5 py-3 border-round"
              >
                <p class="text-white text-xl line-height-3">
                  Capital Required<br /><b>$250M</b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="p-3 border-round-sm content-card text-gray-600 overflow-hidden"
            >
            <GeneralMap v-if="record && parsedCoordinates" :vectors="parsedCoordinates" />
            <pv-message v-if="!(record && parsedCoordinates)" :closable="false"
                >A map will appear when an asset is selected.</pv-message
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="grid">
          <div class="col-6">
            <div class="p-3 border-round-sm content-card text-gray-600">
              <apex-chart
                width="100%"
                :options="scoreChartOptions"
                :series="scoreSeries"
              ></apex-chart>
            </div>
          </div>
          <div class="col-6">
            <div class="p-3 border-round-sm content-card text-gray-600">
              <apex-chart
                width="100%"
                :options="assetChartOptions"
                :series="assetSeries"
              ></apex-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <pv-fieldset
          legend="Risk Rating Description"
          :toggleable="true"
          :collapsed="true"
        >
          <div class="grid font-semibold">
            <!-- Row 1 -->
            <div class="col-2">Prime</div>
            <div class="col-2">
              <div class="grid">
                <div class="col-12" style="background-color: #70ad47">Nnn</div>
                <div class="col-12" style="background-color: #a9d08e">Nn</div>
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-12">
                  Optimium naturally managed state, economically sustainable
                  with no ecosystem volatility or threats
                </div>
                <div class="col-12">
                  Natural restored state with optimum supply from ecosystem
                  services very low volaitility or risk
                </div>
              </div>
            </div>

            <!-- Row 2 -->
            <div class="col-2">Sustainable</div>
            <div class="col-2">
              <div class="grid">
                <div class="col-12" style="background-color: #c6e0b4">N</div>
                <div class="col-12" style="background-color: #e2efda">Sss</div>
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-12">
                  Asset achieved high integrity of ecosystem services with
                  minimal risk related volatility
                </div>
                <div class="col-12">
                  Protected natural or sustainable managed state with net supply
                  of ecosystem services and low volatility
                </div>
              </div>
            </div>

            <!-- Row 3 -->
            <div class="col-2">Functioning</div>
            <div class="col-2">
              <div class="grid">
                <div class="col-12" style="background-color: #fce4d6">Ss</div>
                <div class="col-12" style="background-color: #f8cbad">S</div>
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-12">
                  Sustainable management practice where ecosystem services are
                  being managed for enhancement
                </div>
                <div class="col-12">
                  Evidence of sustainable management practice and protection of
                  ecosystem services
                </div>
              </div>
            </div>

            <!-- Row 4 -->
            <div class="col-2">Sub-prime</div>
            <div class="col-2">
              <div class="grid">
                <div class="col-12" style="background-color: #f4b084">R</div>
                <div class="col-12" style="background-color: #ed7d31">Rr</div>
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-12">
                  Unsustainable but renewable ecosystem use, some risk of
                  service failure but condition can be reversed
                </div>
                <div class="col-12">
                  At risk but non-renewable ecosystem use, high risk of
                  ecosystem service failure
                </div>
              </div>
            </div>

            <!-- Row 5 -->
            <div class="col-2">Non-investment</div>
            <div class="col-2">
              <div class="grid">
                <div class="col-12" style="background-color: #ff0000">Rrr</div>
                <div class="col-12" style="background-color: #c00000">D</div>
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-12">
                  High natural risk, declining natural state, beyond condition
                  reversal
                </div>
                <div class="col-12">
                  Destroyed, non reversable extractive use, high risk of
                  ecosystem collapse
                </div>
              </div>
            </div>
          </div>
        </pv-fieldset>
      </div>
      <!-- <div class="col-12">
        <pv-fieldset
          legend="Investment Headlines"
          :toggleable="true"
          :collapsed="true"
        >
          <div class="grid">
            <div class="col-6">
              <div class="grid">
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">ASSET TYPE</div>
                <div class="col-6">Land</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">REGION</div>
                <div class="col-6">Sub Saharan Africa</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">LAND AREA</div>
                <div class="col-6">730,100 ha</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">MARKET STATUS</div>
                <div class="col-6">Equity and Debt</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">LAND TITLE</div>
                <div class="col-6">50 year lease</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">GOVERNMENT CREDIT RATING</div>
                <div class="col-6">Speculative High</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">ECOSYSTEM CREDIT RATING</div>
                <div class="col-6">Prime</div>
                <div class="col-12"><hr /></div>
              </div>
            </div>
            <div class="col-6">
              <div class="grid">
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">TARGET TOTAL RETURN</div>
                <div class="col-6">25%</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">TARGET YIELD</div>
                <div class="col-6">10%</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">OFFEST OPPORTUNITY</div>
                <div class="col-6">Existing</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">
                  NC ADJUSTED COST OF CAPITAL RATE
                </div>
                <div class="col-6">6%</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">
                  POTENTIAL ECOSYSTEM ADDITIONALITY
                </div>
                <div class="col-6">18%</div>
                <div class="col-12"><hr /></div>
                <div class="col-6 font-bold">NATURAL CAPITAL PREMIUM</div>
                <div class="col-6">2.5x</div>
                <div class="col-12"><hr /></div>
              </div>
            </div>
          </div>
        </pv-fieldset>
      </div> -->
    </div>
  </div>
</template>

<script>
import GeneralMap from "./../components/map/GeneralMap.vue";

// :style="{ backgroundImage: `url('${record.define_assets_image_url}')` }"

export default {
  components: {
    GeneralMap,
  },
  data() {
    return {
      records: null,
      record: {},
      dataType: null,
      activityStatus: 1,
      editMode: false,
      selectedRecord: null,
      selectedAsset: null,
      scoreChartOptions: {
        chart: {
          height: 250,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  name: "-",
                  color: "#ffffff",
                },
                {
                  from: 1,
                  to: 10,
                  name: "< 1 - 10",
                  color: "#c00000",
                },
                {
                  from: 11,
                  to: 20,
                  name: "11 - 20",
                  color: "#ff0000",
                },
                {
                  from: 21,
                  to: 30,
                  name: "21 - 30",
                  color: "#ed7d31",
                },
                {
                  from: 31,
                  to: 40,
                  name: "31 - 40",
                  color: "#f4b084",
                },
                {
                  from: 41,
                  to: 50,
                  name: "41 - 50",
                  color: "#f8cbad",
                },
                {
                  from: 51,
                  to: 60,
                  name: "51 - 60",
                  color: "#fce4d6",
                },
                {
                  from: 61,
                  to: 70,
                  name: "61 - 70",
                  color: "#e2efda",
                },
                {
                  from: 71,
                  to: 80,
                  name: "71 - 80",
                  color: "#c6e0b4",
                },
                {
                  from: 81,
                  to: 90,
                  name: "81 - 90",
                  color: "#a9d08e",
                },
                {
                  from: 91,
                  to: 100,
                  name: "91 - 100",
                  color: "#70ad47",
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "ECOSYSTEM RATING INDEX (EPC STYLE DASHBOARD)",
        },
        subtitle: {
          text: "Risk Rating Score",
        },
        labels: [
          "< 1 - 10",
          "11 - 20",
          "21 - 30",
          "31 - 40",
          "41 - 50",
          "51 - 60",
          "61 - 70",
          "71 - 80",
          "81 - 90",
          "91 - 100",
        ],
        legend: {
          show: true,
          position: "bottom",
        },
      },
      scoreSeries: this.getScoreHeatMapData(),
      assetChartOptions: {
        chart: {
          height: 250,
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  name: "Nil",
                  color: "#ffffff",
                },
                {
                  from: 1,
                  to: 10,
                  name: "< 1 - 10",
                  color: "#c00000",
                },
                {
                  from: 11,
                  to: 20,
                  name: "11 - 20",
                  color: "#ff0000",
                },
                {
                  from: 21,
                  to: 30,
                  name: "21 - 30",
                  color: "#ed7d31",
                },
                {
                  from: 31,
                  to: 40,
                  name: "31 - 40",
                  color: "#f4b084",
                },
                {
                  from: 41,
                  to: 50,
                  name: "41 - 50",
                  color: "#f8cbad",
                },
                {
                  from: 51,
                  to: 60,
                  name: "51 - 60",
                  color: "#fce4d6",
                },
                {
                  from: 61,
                  to: 70,
                  name: "61 - 70",
                  color: "#e2efda",
                },
                {
                  from: 71,
                  to: 80,
                  name: "71 - 80",
                  color: "#c6e0b4",
                },
                {
                  from: 81,
                  to: 90,
                  name: "81 - 90",
                  color: "#a9d08e",
                },
                {
                  from: 91,
                  to: 100,
                  name: "91 - 100",
                  color: "#70ad47",
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
        },
        title: {
          text: "Impact Additionality (Alpha)",
        },
        /*  subtitle: {
          text: "Risk Rating Score",
        }, */
        labels: ["Current", "Planned", "Unplanned"],
        legend: {
          show: false,
          position: "bottom",
        },
      },
      assetSeries: this.getAssetHeatMapData([]),
    };
  },
  mounted() {
    this.fetchAssets().then((data) => {
      this.records = data;
    });

    this.record.define_assets_image_url = require("@/assets/placeholder.png");
  },
  computed: {
    parsedCoordinates() {
      try {
        return JSON.parse(this.record.define_assets_polygon_coordinates);
      } catch (error) {
        //console.error('Failed to parse coordinates:', error);
        return null;
      }
    }
  },
  methods: {
    async fetchAssets() {
      const bodyData = { action_type: "fetch_assets" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchErTable() {
      //fetch only if an asset has been selected

      if (this.selectedRecord) {
        let bodyData = { action_type: "fetch_er_tables" };
        let criteria = {
          econ_data_link_asset_uuid: this.selectedRecord,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    assetSelected() {
      //console.log(this.records);
      let my_array = this.records;

      let criteria = {
        uuid: this.selectedRecord,
      };

      let final_array = this.$store.getters["common/filterObjects"](
        my_array,
        criteria
      );

      //console.log(final_array);

      this.record = final_array[0];

      //console.log(this.record.define_assets_polygon_coordinates);

      this.fetchErTable().then((data) => {
        //console.log(data);
        this.getAssetHeatMapData(data);
      });
    },
    getScoreHeatMapData() {
      let data = [
        {
          name: "Non-investment",
          data: [5, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "",
          data: [15, 15, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Sub-prime",
          data: [25, 25, 25, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "",
          data: [35, 35, 35, 35, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Functioning",
          data: [45, 45, 45, 45, 45, 0, 0, 0, 0, 0],
        },
        {
          name: "",
          data: [55, 55, 55, 55, 55, 55, 0, 0, 0, 0],
        },
        {
          name: "Sustainable",
          data: [65, 65, 65, 65, 65, 65, 65, 0, 0, 0],
        },
        {
          name: "",
          data: [75, 75, 75, 75, 75, 75, 75, 75, 0, 0],
        },
        {
          name: "Prime",
          data: [85, 85, 85, 85, 85, 85, 85, 85, 85, 0],
        },
        {
          name: "",
          data: [95, 95, 95, 95, 95, 95, 95, 95, 95, 95],
        },
      ];

      return data;
    },
    getAssetHeatMapData(data) {
      //console.log(data);

      //weighted eri current $final_data['risk_rate']['current']['risk_rate_weighted_eri']
      //weighted eri planned $final_data['risk_rate']['planned']['risk_rate_weighted_eri']
      //weighted eri unplanned $final_data['risk_rate']['unplanned']['risk_rate_weighted_eri']

      let current_val = 0;
      let planned_val = 0;
      let unplanned_val = 0;

      /* current_val = 95;
      planned_val = 9;
      unplanned_val = 78; */

      if (
        data &&
        Object.prototype.hasOwnProperty.call(data,"risk_rate") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate,"current") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate.current,"risk_rate_weighted_eri")
      )
        current_val = Number(data.risk_rate.current.risk_rate_weighted_eri);
      if (
        data &&
        Object.prototype.hasOwnProperty.call(data,"risk_rate") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate,"planned") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate.planned,"risk_rate_weighted_eri")
      )
        planned_val = data.risk_rate.planned.risk_rate_weighted_eri;
      if (
        data &&
        Object.prototype.hasOwnProperty.call(data,"risk_rate") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate,"unplanned") &&
        Object.prototype.hasOwnProperty.call(data.risk_rate.unplanned,"risk_rate_weighted_eri")
      )
        unplanned_val = data.risk_rate.unplanned.risk_rate_weighted_eri;

        if (data &&
        Object.prototype.hasOwnProperty.call(data,"risk_rate")&&
        Object.prototype.hasOwnProperty.call(data.risk_rate,"current")&&
        Object.prototype.hasOwnProperty.call(data.risk_rate.current,"risk_rate_weighted_eri"))
      console.log(data.risk_rate.current.risk_rate_weighted_eri);

      let arr = [
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
        { name: "", data: [0, 0, 0] },
      ];

      let ranges = [
        { min: 0, max: 10, index: 0 },
        { min: 11, max: 20, index: 1 },
        { min: 21, max: 30, index: 2 },
        { min: 31, max: 40, index: 3 },
        { min: 41, max: 50, index: 4 },
        { min: 51, max: 60, index: 5 },
        { min: 61, max: 70, index: 6 },
        { min: 71, max: 80, index: 7 },
        { min: 81, max: 90, index: 8 },
        { min: 91, max: 100, index: 9 },
      ];

      //current_val
      for (let range of ranges) {
        if (current_val >= range.min && current_val <= range.max) {
          arr[range.index].data[0] = current_val;
        }
      }

      //planned_val
      for (let range of ranges) {
        if (planned_val >= range.min && planned_val <= range.max) {
          arr[range.index].data[1] = planned_val;
        }
      }

      //unplanned_val
      for (let range of ranges) {
        if (unplanned_val >= range.min && unplanned_val <= range.max) {
          arr[range.index].data[2] = unplanned_val;
        }
      }

      this.assetSeries = arr;
      return arr;
    },
  },
};
</script>
