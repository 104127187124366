import { createStore } from 'vuex';

import defAssetsModule from './modules/def-assets/index.js';
import defInputsModule from './modules/def-inputs/index.js';
import defMetricsModule from './modules/def-metrics/index.js';
import defScoresModule from './modules/def-scores/index.js';
import econDataentryModule from './modules/econ-dataentry/index.js';
import secUsermanagerModule from './modules/sec-usermanager/index.js';
import authModule from './modules/auth/index.js';
import commonModule from './modules/_common/index.js';

const store = createStore({ 
  modules: {
    defAssets: defAssetsModule,
    defInputs: defInputsModule,
    defMetrics: defMetricsModule,
    defScores: defScoresModule,
    econDataentry: econDataentryModule,
    secUsermanager: secUsermanagerModule,
    common: commonModule,
    auth:authModule
  },
  state:{
    endpointUrl : "/action/get_data.php",
    authUrl : "/action/auth.php"
  },
});

export default store;