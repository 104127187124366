import { createRouter, createWebHistory } from "vue-router";

import MainPage from "./pages/MainPage";
import SecurityManager from "./pages/security/SecurityManager";
import DefineAssets from "./pages/defineassets/DefineAssets";
import DefineInputs from "./pages/defineinputs/DefineInputs";
import DefineMetrics from "./pages/definemetrics/DefineMetrics";
import DefineScores from "./pages/definescores/DefineScores";
import EconData from "./pages/econdata/EconData";
import AssetSummary from "./pages/assetsummary/AssetSummary";
import ErTables from "./pages/ertables/ErTables";
import LogIn from "./pages/LogIn";
import NotFound from "./pages/NotFound";
import store from "./store/index.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: MainPage, meta: { requiresAuth: true }},
    { path: "/defassets", component: DefineAssets, meta: { requiresAuth: true } },
    { path: "/definputs", component: DefineInputs, meta: { requiresAuth: true } },
    { path: "/defmetrics", component: DefineMetrics, meta: { requiresAuth: true } },
    { path: "/defscores", component: DefineScores, meta: { requiresAuth: true } },
    { path: "/vegetation", component: EconData, meta: { requiresAuth: true } },
    { path: "/biodiversity", component: EconData, meta: { requiresAuth: true } },
    { path: "/water", component: EconData, meta: { requiresAuth: true } },
    { path: "/carbon", component: EconData, meta: { requiresAuth: true } },
    { path: "/social", component: EconData, meta: { requiresAuth: true } },
    { path: "/assetsummary", component: AssetSummary, meta: { requiresAuth: true } },
    { path: "/ertables", component: ErTables, meta: { requiresAuth: true } },
    { path: "/authmanager", component: SecurityManager, meta: { requiresAuth: true } },
    { path: "/auth", component: LogIn, meta: { requiresUnauth: true }  },
    // { path: "/z/:authtoken", component: PlayerAuth, props: true },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    next('/auth');
  }else {
    next();
  }
});

export default router;
