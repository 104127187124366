export default {
  filterObjects: (state) => (array, criteria) => {
    console.log(state.val);
    const result = [];
    for (const obj of array) {
      let matches = true;
      for (const key in criteria) {
        if (String(obj[key]) !== String(criteria[key])) {
          matches = false;
          break;
        }
      }
      if (matches) {
        result.push(obj);
        if (result.length === 4) {
          break;
        }
      }
    }
    return result;
  },
  findObjectAndReturnValue:
    (state) => (arrayToSearch, keyToMatch, valueToMatch, keyToReturn) => {
      console.log(state.val);
      let obj = arrayToSearch.find((item) => item[keyToMatch] === valueToMatch);

      if (obj && keyToReturn in obj) {
        return obj[keyToReturn];
      } else {
        // Return some default value or throw an error if the object or key was not found
        return "";
      }
    },
};
