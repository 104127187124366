export default {
    getUserId(state) {
      return state.userId;
    },
    getRoleId(state) { 
      return state.roleId;
    },
    getFullname(state) {
      return state.fullname;
    },
    getAuthToken(state) {
      return state.authToken;
    },
    isAuthenticated(state) {
      return !!state.authToken;
    },
    didAutoLogout(state) {
      return state.didAutoLogout;
    },
    prepAuthHeaders: (state) => (actionType) => {
      const userId = state.userId
        ? state.userId
        : localStorage.getItem("userId");
      const authToken = state.authToken
        ? state.authToken
        : localStorage.getItem("authToken");
  
      const authHeader = {
        action_type: actionType,
        user_uuid: userId,
        auth_token: authToken,
      };
  
      return authHeader;
    },
  };
  