<template>
  <div :class="{ 'sidebar-open': sidebarOpen, 'sidebar-closed': !sidebarOpen }">
    <sb-menu
      :menu="getMenuItems"
      :collapsed="true"
      @update:collapsed="toggleSidebar"
      theme="white-theme"
      v-if="isLoggedIn"
    />
    <div class="content">
      <the-header v-if="isLoggedIn"></the-header>
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
      <the-footer></the-footer>
    </div>
  </div>
</template>

<!-- https://www.youtube.com/watch?v=CfTvye31Ad0 -->

<script>
import TheHeader from "./components/layout/TheHeader";
import TheFooter from "./components/layout/TheFooter";
import {
  dashboard_menu,
  econ_menu,
  settings_menu,
  security_menu,
} from "./store/modules/auth/data/menu.js";

export default {
  components: { TheHeader, TheFooter },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  mounted() {},
  methods: {
    toggleSidebar(collapsed) {
      this.sidebarOpen = !collapsed;
    },
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters["auth/didAutoLogout"];
    },
    isLoggedIn() {
      //console.log(this.$store.getters["auth/isAuthenticated"]);
      return this.$store.getters["auth/isAuthenticated"];
    },
    getMenuItems() {
      console.log(this.$store.getters["auth/getRoleId"]);
      let roleID = this.$store.getters["auth/getRoleId"];
      let menuItems = [];
      //set menu items
      if (Number(roleID) === 1) {
        //is admin
        menuItems = [
          ...dashboard_menu,
          ...econ_menu,
          ...settings_menu,
          ...security_menu,
        ];
      } else {
        // is gold, is bonze, is silver
        menuItems = [...dashboard_menu, ...econ_menu];
      }

      return menuItems;
    },
  },
  created() {
    this.$store.dispatch("auth/tryLogin");
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/auth");
      }
    },
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"); */

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: #f6f6f6;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid transparent;
}

.content {
  transition: 0.3s ease;
}

.sidebar-open .content {
  margin-left: 290px; /* width of your open sidebar */
}

.sidebar-closed .content {
  margin-left: 65px; /* width of your closed sidebar */
}

.layout-content .card {
  background: var(--surface-card);
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 2rem;
}

.content-card {
  background: var(--surface-card);
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 2rem;
}

.body-content {
  margin: 15px;
}

.use-full-width {
  width: 100%;
}

.content {
  margin-left: 65px;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* .v-sidebar-menu.vsm_collapsed {
  margin-left: 65px;
} */
</style>
