<template>
  <div class="body-content">
    <pv-toolbar class="mb-4">
      <template #start>
        <pv-dropdown
          id="assetId"
          class="use-full-width"
          v-model="assetTypesRecords.econ_data_link_asset_uuid"
          :options="assetTypesRecords"
          optionLabel="define_assets_desc"
          optionValue="uuid"
          filter
          placeholder="Select an Asset"
          @change="assetSelected"
          :disabled="selectedAsset"
        >
        </pv-dropdown>
      </template>

      <!--  <template #end>
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-save"
            class="mr-2"
            label="Save"
            @click="saveRecord()"
          />
          <pv-button
            v-show="selectedRecord && !editMode"
            icon="pi pi-user-edit"
            severity="success"
            class="mr-2"
            label="Edit"
            @click="editRecord()"
          />
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-times"
            severity="danger"
            label="Cancel"
            @click="cancelEdit()"
          />
        </template> -->
    </pv-toolbar>

    <div class="p-3 border-round-sm content-card text-gray-600">
      <pv-accordion :activeIndex="0">
        <pv-accordiontab header="VEGETATION">
          <pv-datatable
            showGridlines
            :value="record.vegetation"
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="vegetation_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_extent"
              header="Extent"
            >
            </pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_condition"
              header="Condition"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_connection"
              header="Connection"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_production"
              header="Production"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_gross_score"
              header="GROSS SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_weighting"
              header="WEIGHTING"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_net_score"
              header="NET SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation_data_confidence"
              header="DATA CONFIDENCE"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
        <pv-accordiontab header="BIODIVERSITY">
          <pv-datatable
            showGridlines
            :value="record.biodiversity"
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="biodiversity_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_extent"
              header="Extent"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_condition"
              header="Condition"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_connection"
              header="Connection"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_production"
              header="Production"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_gross_score"
              header="GROSS SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_weighting"
              header="WEIGHTING"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_net_score"
              header="NET SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity_data_confidence"
              header="DATA CONFIDENCE"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
        <pv-accordiontab header="WATER">
          <pv-datatable
            :value="record.water"
            showGridlines
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="water_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_extent"
              header="Extent"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_condition"
              header="Condition"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_connection"
              header="Connection"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_production"
              header="Production"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_gross_score"
              header="GROSS SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_weighting"
              header="WEIGHTING"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_net_score"
              header="NET SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water_data_confidence"
              header="DATA CONFIDENCE"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
        <pv-accordiontab header="CARBON">
          <pv-datatable
            :value="record.carbon"
            showGridlines
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="carbon_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_extent"
              header="Extent"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_condition"
              header="Condition"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_connection"
              header="Connection"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_production"
              header="Production"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_gross_score"
              header="GROSS SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_weighting"
              header="WEIGHTING"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_net_score"
              header="NET SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon_data_confidence"
              header="DATA CONFIDENCE"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
        <pv-accordiontab header="COMMUNITY">
          <pv-datatable
            :value="record.community"
            showGridlines
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="community_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_extent"
              header="Extent"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_condition"
              header="Condition"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_connection"
              header="Connection"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_production"
              header="Production"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_gross_score"
              header="GROSS SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_weighting"
              header="WEIGHTING"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_net_score"
              header="NET SCORE"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="community_data_confidence"
              header="DATA CONFIDENCE"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
        <pv-accordiontab header="ECOSYSTEM RISK RATE">
          <pv-datatable
            :value="record.risk_rate"
            showGridlines
            tableStyle="min-width: 50rem"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="risk_rate_desc"
              header="-"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_vegetation"
              header="VEGETATION"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_biodiversity"
              header="BIODIVERSITY"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_water"
              header="WATER"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_carbon"
              header="CARBON"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_social"
              header="SOCIAL"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_confidence_avg"
              header="Confidence Avg (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_weighted_eri"
              header="WEIGHTED ERI"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_landscape_bonus"
              header="LANDSCAPE BONUS"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_intervention_pv"
              header="INTERVENTION PV"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="risk_rate_erb_multiplier"
              header="ERB MULTIPLIER"
            ></pv-column>
          </pv-datatable>
        </pv-accordiontab>
      </pv-accordion>
    </div>

    <div class="col-12">
      <pv-fieldset
        legend="ASSET WEIGHTING"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="col-12">
          <pv-datatable
            :value="assetWeightingRecord.current"
            tableStyle="min-width: 50rem"
            showGridlines
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="description"
              header="Asset weighting (Current)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation"
              header="Vegetation"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity"
              header="Biodiversity"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water"
              header="Water"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon"
              header="Carbon"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="social"
              header="Social"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="weighting"
              header="Weighting"
            ></pv-column>
          </pv-datatable>
        </div>
        <div class="col-12">
          <pv-datatable
            :value="assetWeightingRecord.planned"
            tableStyle="min-width: 50rem"
            showGridlines
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="description"
              header="Asset weighting (Potential / At risk)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="vegetation"
              header="Vegetation"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="biodiversity"
              header="Biodiversity"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="water"
              header="Water"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="carbon"
              header="Carbon"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="social"
              header="Social"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="weighting"
              header="Weighting"
            ></pv-column>
          </pv-datatable>
        </div>
      </pv-fieldset>
    </div>

    <div class="col-12">
      <pv-fieldset legend="SENSITIVITY" :toggleable="true" :collapsed="true">
        <div class="col-12">
          <pv-datatable
            :value="assetSensitivityRecord.data_confidence"
            tableStyle="min-width: 50rem"
            showGridlines
            :rowClass="rowClassSensitivityDataConfidence"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="data_confidence_desc"
              header="Data Confidence"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="data_confidence_10"
              header="10%"
            >
            </pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="data_confidence_20"
              header="20%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="data_confidence_30"
              header="30%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="data_confidence_40"
              header="40%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="data_confidence_50"
              header="50%"
            ></pv-column>
          </pv-datatable>
        </div>
        <div class="col-12"><pv-divider /></div>
        <div class="col-12">
          <pv-datatable
            :value="assetSensitivityRecord.natural_zones"
            tableStyle="min-width: 50rem"
            showGridlines
            :rowClass="rowClassSensitivityNaturalZones"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="natural_zones_desc"
              header="Natural Zones"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="natural_zones_30"
              header="30%"
            >
            </pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="natural_zones_40"
              header="40%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="natural_zones_50"
              header="50%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="natural_zones_60"
              header="60%"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="natural_zones_70"
              header="70%"
            ></pv-column>
          </pv-datatable>
        </div>
      </pv-fieldset>
    </div>

    <div class="col-12">
      <pv-fieldset
        legend="LAND ADJUST METRICS (RELATING TO ACTIVE MANAGEMENT INTERVENTION BENEFITS / RISKS)"
        :toggleable="true"
        :collapsed="true"
      >
        <div class="col-12">
          <pv-message :closable="false"
            >Landscape adjustment factor is only applied to valuation component
            because of forward looking scenarios. Scale factor is now reflected
            in future area afyer potetnial aggregations, acquisitions or
            partnerships</pv-message
          >
          <pv-datatable
            showGridlines
            :value="landAdjustMetricsRecord.landscape_area"
            tableStyle="min-width: 50rem"
            :rowClass="rowClassLandMetricsLandscape"
          >
            <pv-column
              field="description"
              headerClass="surface-700 text-white"
              header="Landscape Area"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="0_5"
              header="0.5"
            >
            </pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="0_75"
              header="0.75"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="1"
              header="1"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="1_25"
              header="1.25"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="1_5"
              header="1.5"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="current_ratio_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="current"
              header="Current"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="planned_ratio_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="planned"
              header="Planned"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="unplanned_ratio_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="unplanned"
              header="Un-Planned"
            ></pv-column>
          </pv-datatable>
        </div>
        <div class="col-12">
          <pv-message :closable="false"
            >Intervention difficulty relates to the reality of delivering active
            change on the landscape</pv-message
          >
          <pv-datatable
            showGridlines
            :value="landAdjustMetricsRecord.conversion_difficulty"
            tableStyle="min-width: 50rem"
            :rowClass="rowClassLandMetricsConversionDifficulty"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="description"
              header="Conversion Difficulty"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_current_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_current"
              header="PV Current"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_planned_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_planned"
              header="PV Planned"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_unplanned_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_unplanned"
              header="PV Un-planned"
            ></pv-column>
          </pv-datatable>
        </div>
        <div class="col-12">
          <pv-message :closable="false"
            >Temporal risk relates to hiow long it will take to deliver positive
            outcomes from interventions</pv-message
          >
          <pv-datatable
            showGridlines
            :value="landAdjustMetricsRecord.temporal_risk"
            tableStyle="min-width: 50rem"
            :rowClass="rowClassLandMetricsTemporalRisk"
          >
            <pv-column
              headerClass="surface-700 text-white"
              field="description"
              header="Temporal risk of intervention"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_current_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_current"
              header="PV Current"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_planned_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_planned"
              header="PV Planned"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_unplanned_total_area"
              header="Ratio total area (%)"
            ></pv-column>
            <pv-column
              headerClass="surface-700 text-white"
              style="text-align: center"
              field="pv_unplanned"
              header="PV Un-planned"
            ></pv-column>
          </pv-datatable>
          <div class="col-12">
            <pv-message severity="success" icon="false" :closable="false"
              >
              <h3 style="font-weight:bold; text-decoration:underline;">Method</h3>
              <ul>
                <li>
                  Each environmental asset category is scored out of 100 for the
                  current condition, potential condition and management hurdle
                  condition
                </li>
                <li>
                  Score is calculated on basis of Area x (Extent),(Condition),
                  (Connection) and (Average of Production and Social flows)
                </li>
                <li>
                  Category application into aggregate score is weighted on
                  relevance to asset class
                </li>
                <li>
                  Current condition always allows for additionality with active
                  management improvement of Production and Social benefits as
                  often limited application or evidence
                </li>
                <li>
                  Extent, Condition and Connection are underlying supporting
                  ecosystem factors
                </li>
                <li>
                  Ecosystem Physical Risk rate is translated into cash flow and
                  terminal value application phase based on current, hurdle and
                  target scores
                </li>
                <li>
                  An intervention present value multiplier is included to
                  recognise the variances in temporal and physical intervention
                  difficulty in achieving hurdle and target scores
                </li>
              </ul>
            </pv-message>
          </div>
        </div>
      </pv-fieldset>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      records: null,
      record: {},
      assetTypesRecords: [],
      selectedRecord: null,
      assetWeightingRecord: {},
      assetSensitivityRecord: {},
      landAdjustMetricsRecord: {},
    };
  },
  mounted() {
    this.fetchAssets().then((data) => {
      this.assetTypesRecords = data;
    });
  },
  methods: {
    async fetchAssets() {
      const bodyData = { action_type: "fetch_econ_data_assets" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchRecords() {
      //fetch only if an asset has been selected
      if (this.assetTypesRecords.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_er_tables" };
        let criteria = {
          econ_data_link_asset_uuid:
            this.assetTypesRecords.econ_data_link_asset_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    assetSelected() {
      this.selectedRecord = false;

      console.log("assetSelected");

      this.fetchRecords().then((data) => {
        if (data) {
          this.record = data;
        }

        this.fetchAssetSensitivity().then((f_data) => {
          this.assetSensitivityRecord = f_data;
        });

        this.fetchAssetWeighting().then((g_data) => {
          this.assetWeightingRecord = g_data;
        });

        this.fetchLandAdjustMetrics().then((h_data) => {
          this.landAdjustMetricsRecord = h_data;
        });

        this.selectedRecord = true;
      });
    },
    async fetchAssetWeighting() {
      //fetch only if an asset has been selected
      if (this.assetTypesRecords.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_asset_weighting" };
        let criteria = {
          econ_data_link_asset_uuid:
            this.assetTypesRecords.econ_data_link_asset_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    async fetchAssetSensitivity() {
      //fetch only if an asset has been selected
      if (this.assetTypesRecords.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_sensitivity" };
        let criteria = {
          econ_data_link_asset_uuid:
            this.assetTypesRecords.econ_data_link_asset_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    async fetchLandAdjustMetrics() {
      //fetch only if an asset has been selected
      if (this.assetTypesRecords.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_land_adjust_metrics" };
        let criteria = {
          econ_data_link_asset_uuid:
            this.assetTypesRecords.econ_data_link_asset_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    rowClassSensitivityDataConfidence(data) {
      return [
        { "surface-300 font-bold": data.data_confidence_desc === "Total ERI" },
      ];
    },
    rowClassSensitivityNaturalZones(data) {
      return [
        { "surface-300 font-bold": data.natural_zones_desc === "Total ERI" },
      ];
    },
    rowClassLandMetricsLandscape(data) {
      return [
        { "surface-300 font-bold": data.description === "Scale Scoring" },
      ];
    },
    rowClassLandMetricsConversionDifficulty(data) {
      return [
        {
          "surface-300 font-bold":
            data.description === "Weighted Average Land use area",
        },
      ];
    },
    rowClassLandMetricsTemporalRisk(data) {
      return [
        {
          "surface-300 font-bold":
            data.description === "Weighted Average Land use area",
        },
      ];
    },
  },
};
</script>
