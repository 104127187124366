<template>
  <div class="footer" align="center">
    <hr width="70%" />
    <p>
      Built for Gaia Metrix &#169; {{ `${new Date().getFullYear()}` }}
    </p>
  </div>
</template>

<script>

export default {
 
};
</script>

<style scoped>
.footer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  margin-top: 50px;
  width: 100%;
  color: grey;
  text-align: center;
  height: 60px;
  font-size: 12px;
}
</style>
