let timer;

export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async signup(context, payload) {
    return context.dispatch("validate", {
      ...payload,
      mode: "signup",
    });
  },
  async auth(context, payload) {
    //let url = this.$store.state.authUrl;
    let url = "/action/auth.php";
    let bodyData = {};

    bodyData = {
      username: payload.username,
      password: payload.password,
    };

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(bodyData),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    console.log(responseData);

    const expiresAt = +responseData.expires_at;
    let dateTime = new Date().getTime();
    dateTime = dateTime.toString().replace(/.{0,3}$/, "");
    const expiresIn = expiresAt - dateTime;
    // const expiresIn = 50000;

    localStorage.setItem("authToken", responseData.auth_token);
    localStorage.setItem("userId", responseData.user_uuid);
    localStorage.setItem("roleId", responseData.role_id);
    localStorage.setItem("fullname", responseData.fullname);
    localStorage.setItem("tokenExpiration", expiresAt);

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn * 1000);

    console.log(expiresIn);
    console.log(expiresAt);

    context.commit("setUser", {
      authToken: responseData.auth_token,
      userId: responseData.user_uuid,
      roleId: responseData.role_id,
      fullname: responseData.fullname,
    });
  },
  tryLogin(context) {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const roleId = localStorage.getItem("roleId");
    const fullname = localStorage.getItem("fullname");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    let dateTime = new Date().getTime();
    dateTime = dateTime.toString().replace(/.{0,3}$/, "");
    const expiresIn = +tokenExpiration - dateTime;

    /* console.log(new Date().getTime());
    console.log(dateTime);
    console.log(tokenExpiration);
    console.log(expiresIn); */

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function () {
      context.dispatch("autoLogout");
    }, expiresIn * 1000);

    console.log("tryLogin");

    if (authToken && userId) {
      context.commit("setUser", {
        authToken: authToken,
        userId: userId,
        roleId: roleId,
        fullname: fullname,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("roleId");
    localStorage.removeItem("fullname");
    localStorage.removeItem("tokenExpiration");

    clearTimeout(timer);

    console.log("logout");

    context.commit("setUser", {
      authToken: null,
      userId: null,
      roleId: null,
      fullname: null,
      tokenExpiration: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
};
