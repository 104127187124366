<template>
  <div class="body-content">
    <pv-toast />
    <div class="card">
      <pv-toolbar class="mb-4">
        <template #start>
          <pv-dropdown
            id="assetId"
            class="use-full-width"
            v-model="assetTypesRecords.econ_data_link_asset_uuid"
            :options="assetTypesRecords"
            optionLabel="define_assets_desc"
            optionValue="uuid"
            filter
            placeholder="Select an Asset"
            @change="assetSelected"
            :disabled="selectedAsset"
          >
          </pv-dropdown>
        </template>
        <template #end>
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-save"
            class="mr-2"
            label="Save"
            @click="saveRecord()"
          />
          <pv-button
            v-show="selectedRecord && !editMode"
            icon="pi pi-user-edit"
            severity="success"
            class="mr-2"
            label="Edit"
            @click="editRecord()"
          />
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-times"
            severity="danger"
            label="Cancel"
            @click="cancelEdit()"
          />
        </template>
      </pv-toolbar>

      <div class="grid">
        <div class="col-7">
          <div class="p-3 border-round-sm content-card text-gray-600">
            <pv-accordion :activeIndex="0">
              <pv-accordiontab header="Current Natural Capital stocks">
                <div class="grid">
                  <div class="col-4">Natural forest / IFM</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_n_forest"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_current_n_forest_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Afforestation <i>(Plantation / ARR)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_plantation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_current_plantation_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Agriculture / ALM</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_agriculture"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_current_agriculture_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Savannah / grassland</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_savannah"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_current_savannah_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Conservation arc</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_conservation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_conservation_forest_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Mangrove / wetland</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_mangrove"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_current_mangrove_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Urban</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_current_urban"
                    />
                  </div>
                  <div class="col-4">
                    {{ assetWeightsRecord.asset_summary_current_urban_weight }}
                    %
                  </div>
                  <div class="col-12"><hr /></div>
                  <div class="col-4 font-bold">Total Sum</div>
                  <div class="col-4 font-bold text-center">
                    {{
                      formatNumber(
                        assetWeightsRecord.asset_summary_current_total
                      )
                    }}
                  </div>
                  <div class="col-4">100%</div>
                </div>
              </pv-accordiontab>
              <pv-accordiontab header="Planned Land-use">
                <div class="grid">
                  <div class="col-4"><b>Natural forest / IFM</b></div>
                  <div class="col-4">
                    <pv-inputnumber                    
                      class="light-gray-input"
                      :disabled="true"
                      v-model="record.asset_summary_planned_n_forest"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_n_forest_weight
                    }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Reduced Impact Logging Concession</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_logging_concession"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Community forest</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_community_forest"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-4"><b>Plantation / ARR</b></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_plantation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_plantation_weight
                    }}
                    %
                  </div>
                  <div class="col-4"><b>Agriculture</b></div>
                  <div class="col-4">
                    <pv-inputnumber
                      class="light-gray-input"
                      :disabled="true"
                      v-model="record.asset_summary_planned_agriculture_sum"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_agriculture_weight
                    }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Arable <i>(Agriculture / ALM)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_agriculture"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Cattle <i>(Savannah / grassland)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_savannah"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_savannah_weight
                    }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Community farm / plantation</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_community_farm"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-4"><b>Conservation arc</b></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_conservation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_conservation_weight
                    }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Forest protected</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_forest_protected"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Mangroves protected</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_mangrove"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_planned_mangrove_weight
                    }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-4"><b>Urban Development</b></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_planned_urban"
                    />
                  </div>
                  <div class="col-4">
                    {{ assetWeightsRecord.asset_summary_planned_urban_weight }}
                    %
                  </div>
                  <!-- Break -->
                  <div class="col-1"></div>
                  <div class="col-3">Port, mill and quarry</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="true"
                      class="light-gray-input"
                      v-model="record.asset_summary_planned_urban"
                    />
                  </div>
                  <div class="col-4"></div>
                  <!-- Break -->
                  <div class="col-12"><hr /></div>
                  <div class="col-4 font-bold">Total GMDC Portfolio</div>
                  <div class="col-4 font-bold text-center">
                    {{
                      formatNumber(
                        assetWeightsRecord.asset_summary_planned_total
                      )
                    }}
                  </div>
                  <div class="col-4">100%</div>
                </div>
              </pv-accordiontab>
              <pv-accordiontab header="Unplanned Natural Capital stocks">
                <div class="grid">
                  <div class="col-4">Pristine Rainforest <i>(Natural forest / IFM)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_n_forest"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_n_forest_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Afforestation <i>(Plantation / ARR)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_plantation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_plantation_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Agriculture / ALM</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_agriculture"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_agriculture_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Open savannah <i>(Savannah / grassland)</i></div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_savannah"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_savannah_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Conservation arc</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_conservation"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_conservation_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Mangrove / wetland</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_mangrove"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_mangrove_weight
                    }}
                    %
                  </div>
                  <div class="col-4">Urban</div>
                  <div class="col-4">
                    <pv-inputnumber
                      :disabled="!editMode"
                      v-model="record.asset_summary_unplanned_urban"
                    />
                  </div>
                  <div class="col-4">
                    {{
                      assetWeightsRecord.asset_summary_unplanned_urban_weight
                    }}
                    %
                  </div>
                  <div class="col-12"><hr /></div>
                  <div class="col-4 font-bold">Total Sum</div>
                  <div class="col-4 font-bold text-center">
                    {{
                      formatNumber(
                        assetWeightsRecord.asset_summary_unplanned_total
                      )
                    }}
                  </div>
                  <div class="col-4">100%</div>
                </div>
              </pv-accordiontab>
            </pv-accordion>
          </div>
        </div>
        <div class="col-5">
          <div class="col-12">
            <pv-fieldset legend="Description">
              <p>
                {{ assetData.define_assets_info }}
              </p>
            </pv-fieldset>
          </div>
          <div class="col-12">
            <div
              class="p-3 border-round-sm content-card text-gray-600 overflow-hidden"
            >
              <GeneralMap
                v-if="assetData && parsedCoordinates"
                :vectors="parsedCoordinates"
              />
              <pv-message
                v-if="!(assetData && parsedCoordinates)"
                :closable="false"
                >A map will appear when an asset is selected.</pv-message
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralMap from "./../../components/map/GeneralMap.vue";

export default {
  components: {
    GeneralMap,
  },
  data() {
    return {
      records: null,
      record: {},
      dataType: null,
      activityStatus: 1,
      editMode: false,
      selectedRecord: null,
      selectedAsset: null,
      assetTypesRecords: [],
      assetWeightsRecords: null,
      assetWeightsRecord: {},
      assetData: {},
    };
  },
  mounted() {
    this.fetchAssets().then((data) => {
      this.assetTypesRecords = data;
    });
  },
  computed: {
    parsedCoordinates() {
      try {
        return JSON.parse(this.assetData.define_assets_polygon_coordinates);
      } catch (error) {
        //console.error('Failed to parse coordinates:', error);
        return null;
      }
    },
  },
  methods: {
    async fetchAssets() {
      const bodyData = { action_type: "fetch_econ_data_assets" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchRecords() {
      //fetch only if an asset has been selected
      if (this.assetTypesRecords.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_asset_summary" };
        let criteria = {
          asset_summary_link_asset_uuid:
            this.assetTypesRecords.econ_data_link_asset_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          return data;
        } catch (err) {
          this.$toast.add({
            severity: "error",
            summary: "Error Occured",
            detail: "Please select an Asset and try again later.",
            life: 3000,
          });
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    async fetchWeights() {
      let bodyData = { action_type: "fetch_weights" };
      let criteria = {
        asset_summary_link_asset_uuid:
          this.assetTypesRecords.econ_data_link_asset_uuid,
      };

      bodyData = { ...bodyData, ...criteria };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    assetSelected() {
      this.selectedAsset = false;
      this.editMode = false;
      this.record = {};
      this.assetWeightsRecord = {};
      this.assetData = {};
      this.fetchRecords().then((data) => {
        if (data[0]) {
          this.record = data[0];

          //
          this.fetchWeights().then((data_2) => {
            this.assetWeightsRecord = data_2[0];
          });

          let keyToMatch = "";
          let keyToReturn = "";
          let valueToMatch = "";

          //get description
          keyToMatch = "uuid";
          keyToReturn = "define_assets_info";
          valueToMatch = this.assetTypesRecords.econ_data_link_asset_uuid;
          this.assetData["define_assets_info"] = this.$store.getters[
            "common/findObjectAndReturnValue"
          ](this.assetTypesRecords, keyToMatch, valueToMatch, keyToReturn);

          //get polygon
          keyToMatch = "uuid";
          keyToReturn = "define_assets_polygon_coordinates";
          valueToMatch = this.assetTypesRecords.econ_data_link_asset_uuid;
          this.assetData["define_assets_polygon_coordinates"] =
            this.$store.getters["common/findObjectAndReturnValue"](
              this.assetTypesRecords,
              keyToMatch,
              valueToMatch,
              keyToReturn
            );
        }
        this.selectedRecord = true;
      });
    },
    editRecord() {
      this.editMode = true;
    },
    cancelEdit() {
      this.assetSelected();
      for (let key in this.record) {
        if (key !== "econ_data_link_asset_uuid") {
          this.record[key] = null;
        }
      }
    },
    async saveRecord() {
      /*

      this.isLoading = true;

      let getAuth = this.$store.getters["auth/prepAuthHeaders"](
        "fetch_ecosystems"
      );

      const bodyData = { ...getAuth };

      */

      let bodyData = { action_type: "save_asset_summary" };

      let criteria = {
        asset_summary_link_asset_uuid:
          this.assetTypesRecords.econ_data_link_asset_uuid,
      };

      bodyData = { ...bodyData, ...this.record, ...criteria };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();

        this.fetchRecords().then((data) => {
          if (data[0]) {
            this.record = data[0];

            //
            this.fetchWeights().then((data_2) => {
              this.assetWeightsRecord = data_2[0];
            });
          }
        });

        this.editMode = false;

        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Record has been Saved/Updated",
          life: 3000,
        });

        return data;
      } catch (err) {
        console.log(err);
        this.editMode = false;

        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "An error has occured. Please try again later.",
          life: 3000,
        });

        return (this.error = "Failed to load, try later.");
      }
    },
    formatNumber(value) {
      // This will add commas as thousand separators
      if (value) {
        return Number(value).toLocaleString("en-US");
      } else {
        return "0";
      }
    },
  },
};
</script>

<style scoped>
.light-gray-input {
  background-color: rgb(237, 230, 230);
}
</style>
