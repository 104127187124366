<template>
    <header>
      <!-- <img src="../../assets/logo.png" alt="Medani Logo" /> -->
      <nav>
        <h1>
          <router-link to="/">Gaia Metrix </router-link>
        </h1>
        <ul>
          <li v-if="isLoggedIn" class="text-white font-bold mr-5">Welcome, {{ getFullname }}</li>
          <li v-if="isLoggedIn">
            <pv-button 
              label="Logout" 
              severity="warning" 
              @click="logOut"
              outlined />
          </li>        
        </ul>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    computed: {
      isLoggedIn() {       
        return this.$store.getters["auth/isAuthenticated"];
      },
      getFullname() {   
        //console.log("getFullname",this.$store.getters["auth/getFullname"]);    
        return this.$store.getters["auth/getFullname"];
      },
    },
    methods:{
      logOut(){
        this.$store.dispatch("auth/logout");
        this.$router.replace("/auth");
      }
    }
  };
  </script>
  
  <style scoped>
  header {
    width: 100%;
    height: 5rem;
    background-color: #3d008d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  header a {
    text-decoration: none;
    color: #f391e3;
    display: inline-block;
    padding: .75rem 1.5rem;
    border: 1px solid transparent;
  }
  
  a:active,
  a:hover,
  a.router-link-active {
    border: 1px solid #f391e3;
  }
  
  h1 {
    margin: 0;
  }
  
  h1 a {
    color: white;
    margin: 0;
  }
  
  h1 a:hover,
  h1 a:active,
  h1 a.router-link-active {
    border-color: transparent;
  }
  
  header nav {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  li {
    margin: 0 0.5rem;
  }
  
  img {
    margin-left: 1rem;
    width: 50px;
    height: 50px;
  }
  </style>
  