<template>
  <div class="row body-content">
    <pv-toast />
    <div class="card">
      <h2 class="text-gray-600" align="center">{{ headerInfo }}</h2>
      <pv-toolbar class="mb-4">
        <template #start>
          <pv-dropdown
            id="assetId"
            class="use-full-width"
            v-model="record.econ_data_link_asset_uuid"
            :options="assetTypesRecords"
            optionLabel="define_assets_desc"
            optionValue="uuid"
            filter
            placeholder="Select an Asset"
            @change="assetSelected"
            :disabled="selectedAsset"
          >
          </pv-dropdown>
        </template>
        <template #center>
          <i class="pi pi-bars p-toolbar-separator mr-2" />
          <span class="p-buttonset">
            <pv-button
              v-if="allowedRoles([1, 2, 3, 4])"
              size="large"
              label="Current"
              icon="pi pi-check"
              :outlined="!activityStatus1"
              @click="switchActivityStatus(1)"
            />
            <pv-button
              v-if="allowedRoles([1, 3, 4])"
              size="large"
              label="Planned"
              icon="pi pi-history"
              :outlined="!activityStatus2"
              @click="switchActivityStatus(2)"
            />
            <pv-button
              v-if="allowedRoles([1, 4])"
              size="large"
              label="Unplanned"
              icon="pi pi-eject"
              :outlined="!activityStatus3"
              @click="switchActivityStatus(3)"
            />
          </span>
          <i class="pi pi-bars p-toolbar-separator mr-2" />
        </template>
        <template #end>
          <pv-button
            v-show="selectedAsset && !editMode"
            icon="pi pi-eraser"
            class="mr-2"
            severity="warning"
            label="Reset"
            @click="resetForm()"
          />
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-save"
            class="mr-2"
            label="Save"
            @click="saveRecord()"
          />
          <pv-button
            v-show="selectedRecord && !editMode"
            icon="pi pi-user-edit"
            severity="success"
            class="mr-2"
            :label="editButtonLabel"
            @click="editRecord()"
          />
          <pv-button
            v-show="selectedRecord && editMode"
            icon="pi pi-times"
            severity="danger"
            label="Cancel"
            @click="cancelEdit()"
          />
        </template>
      </pv-toolbar>
    </div>

    <div class="grid">
      <div class="col-4">
        <div class="p-3 border-round-sm content-card text-gray-600">
          <div class="col-12">
            <pv-dropdown
              id="metricID"
              class="use-full-width"
              v-model="record.econ_data_link_metric_def_id"
              :options="metricDefsRecords"
              optionLabel="option_metric_defination_desc"
              optionValue="id"
              :disabled="!selectedAsset || editMode"
              placeholder="Select Metric"
              @change="metricSelected"
            >
            </pv-dropdown>
          </div>
          <div class="col-12">
            <pv-dropdown
              id="subMetricID"
              class="use-full-width"
              v-model="record.econ_data_link_sub_metric_def_uuid"
              :options="subMetricDefsFilteredRecords"
              optionLabel="define_sub_metric_defination_desc"
              optionValue="uuid"
              filter
              :disabled="!selectedMetric || editMode"
              placeholder="Select Sub-Metric"
              @change="subMetricSelected"
            >
            </pv-dropdown>
          </div>
          <div class="col-12"></div>
          <div class="col-12">
            <b>Metric Rationale</b><br />
            <hr />
          </div>
          <div class="col-12">
            {{ selData.metric_rationale }}
          </div>
          <div class="col-12"></div>
          <div class="col-12"></div>
          <div class="col-12"></div>
          <div class="grid">
            <div class="col-3"><b>Metric</b></div>
            <div class="col-3">(Unit)</div>
            <div class="col-6">
              <pv-dropdown
                id="kpiMetricID"
                class="use-full-width"
                v-model="record.econ_data_link_kpi_metric_uuid"
                :options="kpiDataInputsRecords"
                optionLabel="kpi_data_inputs_desc"
                optionValue="uuid"
                filter
                :disabled="!editMode"
                placeholder="Select a KPI Metric"
              ></pv-dropdown>
            </div>
            <div class="col-12"><hr /></div>
            <div class="col-3"><b>Data reference</b></div>
            <div class="col-3">(Method used)</div>
            <div class="col-6">
              <pv-dropdown
                id="dataRefID"
                class="use-full-width"
                v-model="record.econ_data_link_data_reference_uuid"
                :options="kpiDataInputsRecords"
                optionLabel="kpi_data_inputs_desc"
                optionValue="uuid"
                filter
                :disabled="!editMode"
                placeholder="Select a Data reference"
              ></pv-dropdown>
            </div>
            <div class="col-12"><hr /></div>
            <div class="col-3"><b>Data Source</b></div>
            <div class="col-3">(3rd Party)</div>
            <div class="col-6">
              <pv-dropdown
                id="dataSourceID"
                class="use-full-width"
                v-model="record.econ_data_link_data_source_uuid"
                :options="kpiDataInputsRecords"
                optionLabel="kpi_data_inputs_desc"
                optionValue="uuid"
                filter
                :disabled="!editMode"
                placeholder="Select a Data Source"
              ></pv-dropdown>
            </div>
            <div class="col-12" v-if="showAutoInfo">
              <pv-message :closable="false"
                >This is an automatic data source. Data will be refreshed every
                24 hours.</pv-message
              >
            </div>
            <div class="col-12"><hr /></div>
            <div class="col-3"><b>Confidence Rating</b></div>
            <div class="col-3">(Data 0-100%)</div>
            <div class="col-6">
              <pv-inputnumber
                class="use-full-width"
                inputId="con_rating"
                :min="0"
                :max="100"
                :disabled="!editMode"
                v-model="record.econ_data_confidence_rating"
                suffix="%"
              />
            </div>
          </div>
        </div>
        <div class="p-3 border-round-sm content-card text-gray-600">--</div>
      </div>
      <div class="col-8">
        <div class="p-3 border-round-sm content-card text-gray-600">
          <div class="grid">
            <div class="col-3"></div>
            <div class="col-2"><b>Score</b></div>
            <div class="col-5"><b>Defination</b></div>
            <div class="col-2"><b>Area Weighting</b></div>
            <div class="col-12"><hr /></div>
            <div class="col-3">Natural forest / IFM</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="n_forest"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_natural_forest"
                @update:modelValue="updateN_forest"
              />
            </div>
            <div class="col-5">
              {{ selData.n_forest }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.n_forest }} %
            </div>
            <div class="col-3">Plantation / ARR</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="plantation"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_plantation"
                @update:modelValue="updatePlantation"
              />
            </div>
            <div class="col-5">
              {{ selData.plantation }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.plantation }} %
            </div>
            <div class="col-3">Agriculture / ALM</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="agriculture"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_agriculture"
                @update:modelValue="updateAgriculture"
              />
            </div>
            <div class="col-5">
              {{ selData.agriculture }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.agriculture }} %
            </div>
            <div class="col-3">Savannah / grassland</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="savannah"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_savannah"
                @update:modelValue="updateSavannah"
              />
            </div>
            <div class="col-5">
              {{ selData.savannah }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.savannah }} %
            </div>
            <div class="col-3">Conservation arc</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="conservation"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_conservation_arc"
                @update:modelValue="updateConservation"
              />
            </div>
            <div class="col-5">
              {{ selData.conservation }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.conservation }} %
            </div>
            <div class="col-3">Mangrove / wetland</div>
            <div class="col-2">
              <pv-inputnumber
                inputId="mangrove"
                :min="0"
                :max="5"
                :disabled="!editMode"
                :modelValue="record.econ_data_mangrove"
                @update:modelValue="updateMangrove"
              />
            </div>
            <div class="col-5">
              {{ selData.mangrove }}
            </div>
            <div class="col-2 text-center">
              {{ activeWeightsRecord.mangrove }} %
            </div>
            <div class="col-12"><hr /></div>
            <div class="col-3 text-right"><b>Asset Score</b></div>
            <div class="col-2 text-right">{{ activeAssetScoreRecord }}</div>
            <div class="col-5"></div>
            <div class="col-2 text-center">--</div>
          </div>
        </div>
        <div class="p-3 border-round-sm content-card text-gray-600">
          <div class="grid">
            <div class="col-12">
              <b>KPI Analysis</b>
              <hr />
            </div>
            <div class="col-6">
              Asset limiting factors
              <span class="p-float-label">
                <pv-textarea
                  rows="5"
                  cols="30"
                  :disabled="!editMode"
                  v-model="record.econ_data_asset_limiting_factors"
                />
              </span>
            </div>
            <div class="col-6">
              Actions for improvement
              <span class="p-float-label">
                <pv-textarea
                  rows="5"
                  cols="30"
                  :disabled="!editMode"
                  v-model="record.econ_data_actions_for_improvement"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      records: null,
      record: {},
      dataType: null,
      activityStatus: 1,
      editMode: false,
      selectedRecord: null,
      selectedAsset: null,
      selectedMetric: null,
      selectedSubMetric: null,
      assetTypesRecords: [],
      metricDefsRecords: [],
      subMetricDefsRecords: [],
      subMetricDefsFilteredRecords: [],
      kpiDataInputsRecords: [],
      scoresRecords: [],
      selData: {},
      headerInfo: "",
      assetWeightsRecord: {},
      activeWeightsRecord: {},
      assetScoreRecord: {},
      activeAssetScoreRecord: "",
      showAutoInfo: false,
      userRoleID: null,
    };
  },
  mounted() {
    console.log("mounted");
    this.records = null;
    this.record = {};
    this.dataType = null;
    this.activityStatus = 1;
    this.editMode = false;
    this.selectedRecord = null;
    this.selectedAsset = null;
    this.selectedMetric = null;
    this.selectedSubMetric = null;
    this.assetTypesRecords = [];
    this.metricDefsRecords = [];
    this.subMetricDefsRecords = [];
    this.subMetricDefsFilteredRecords = [];
    this.kpiDataInputsRecords = [];
    this.scoresRecords = [];
    this.selData = {};
    this.userRoleID = this.$store.getters["auth/getRoleId"];

    let path = this.$route.path.slice(1);

    if (path == "vegetation") {
      this.dataType = 1;
      this.headerInfo = "Vegetation";
    } else if (path == "biodiversity") {
      this.dataType = 2;
      this.headerInfo = "Biodiversity";
    } else if (path == "water") {
      this.dataType = 3;
      this.headerInfo = "Water";
    } else if (path == "carbon") {
      this.dataType = 4;
      this.headerInfo = "Carbon";
    } else if (path == "social") {
      this.dataType = 5;
      this.headerInfo = "Social";
    } else {
      this.$toast.add({
        severity: "error",
        summary: "Error Occured",
        detail: "An error has occured. Please reload the page.",
        life: 3000,
      });
    }

    this.fetchAssets().then((data) => {
      this.assetTypesRecords = data;
    });

    this.fetchMetricDefinations().then((data) => {
      this.metricDefsRecords = data;
    });

    this.fetchSubMetricDefinations().then((data) => {
      this.subMetricDefsRecords = data;
    });

    this.fetchKPIDataInputs().then((data) => {
      this.kpiDataInputsRecords = data;
    });

    this.fetchScores().then((data) => {
      this.scoresRecords = data;
    });
  },
  computed: {
    activityStatus1() {
      return this.activityStatus === 1;
    },
    activityStatus2() {
      return this.activityStatus === 2;
    },
    activityStatus3() {
      return this.activityStatus === 3;
    },
    isEditMode() {
      return this.editMode;
    },
    editButtonLabel() {
      return !this.record.uuid ? "New Record" : "Edit";
    },
  },
  watch: {
    $route(to) {
      // Update the reactive data property when the route changes
      console.log("route change");

      this.records = null;
      this.record = {};
      this.dataType = null;
      this.activityStatus = 1;
      this.editMode = false;
      this.selectedRecord = null;
      this.selectedAsset = null;
      this.selectedMetric = null;
      this.selectedSubMetric = null;
      this.assetTypesRecords = [];
      this.metricDefsRecords = [];
      this.subMetricDefsRecords = [];
      this.subMetricDefsFilteredRecords = [];
      this.kpiDataInputsRecords = [];
      this.scoresRecords = [];
      this.selData = {};
      this.assetWeightsRecord = {};
      this.activeWeightsRecord = {};
      this.activeAssetScoreRecord = "";

      let path = to.fullPath.slice(1);

      if (path == "vegetation") {
        this.dataType = 1;
        this.headerInfo = "Vegetation";
      } else if (path == "biodiversity") {
        this.dataType = 2;
        this.headerInfo = "Biodiversity";
      } else if (path == "water") {
        this.dataType = 3;
        this.headerInfo = "Water";
      } else if (path == "carbon") {
        this.dataType = 4;
        this.headerInfo = "Carbon";
      } else if (path == "social") {
        this.dataType = 5;
        this.headerInfo = "Social";
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "An error has occured. Please reload the page.",
          life: 3000,
        });
      }

      this.fetchAssets().then((data) => {
        this.assetTypesRecords = data;
      });

      this.fetchMetricDefinations().then((data) => {
        this.metricDefsRecords = data;
      });

      this.fetchSubMetricDefinations().then((data) => {
        this.subMetricDefsRecords = data;
      });

      this.fetchKPIDataInputs().then((data) => {
        this.kpiDataInputsRecords = data;
      });

      this.fetchScores().then((data) => {
        this.scoresRecords = data;
      });
    },
    "record.econ_data_link_data_source_uuid"(newValue) {
      //get the code
      let keyToMatch = "uuid";
      let keyToReturn = "kpi_data_inputs_code";
      let valueToMatch = newValue;

      let my_val = this.findObjectAndReturnValue(
        this.kpiDataInputsRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );

      if (my_val) {
        if (my_val.startsWith("AUTO-")) {
          this.showAutoInfo = true;
        } else {
          this.showAutoInfo = false;
        }
      } else {
        this.showAutoInfo = false;
      }
    },
  },
  methods: {
    async fetchRecords() {
      //fetch only if an asset has been selected
      if (this.record.econ_data_link_asset_uuid) {
        let bodyData = { action_type: "fetch_econ_data" };
        let criteria = {
          econ_data_link_asset_uuid: this.record.econ_data_link_asset_uuid,
          econ_data_link_type_id: this.dataType,
          econ_data_activity_status: this.activityStatus,
          econ_data_link_metric_def_id:
            this.record.econ_data_link_metric_def_id,
          econ_data_link_sub_metric_def_uuid:
            this.record.econ_data_link_sub_metric_def_uuid,
        };
        let url = this.$store.state.endpointUrl;

        bodyData = { ...bodyData, ...criteria };
        try {
          //fetching data from api
          let response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          });

          let data = await response.json();

          if (data[0]) {
            this.selData.metric_rationale =
              data[0].econ_data_link_sub_metric_def_rationale;

            let keyToMatch = "define_scores_link_sub_metric_uuid";
            let valueToMatch = data[0].econ_data_link_sub_metric_def_uuid;

            this.selData.n_forest = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_natural_forest)
            );
            this.selData.plantation = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_plantation)
            );
            this.selData.agriculture = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_agriculture)
            );
            this.selData.savannah = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_savannah)
            );
            this.selData.conservation = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_conservation_arc)
            );
            this.selData.mangrove = this.findObjectAndReturnValue(
              this.scoresRecords,
              keyToMatch,
              valueToMatch,
              this.getScoreKeyField(data[0].econ_data_mangrove)
            );
          }

          return data;
        } catch (err) {
          console.log(err);
          return (this.error = "Failed to load, try later.");
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "Please select an Asset and try again later.",
          life: 3000,
        });

        return [];
      }
    },
    async fetchAssets() {
      const bodyData = { action_type: "fetch_econ_data_assets" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchScores() {
      const bodyData = { action_type: "fetch_scores" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchMetricDefinations() {
      const bodyData = { action_type: "fetch_option_metricdefs" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchSubMetricDefinations() {
      const bodyData = { action_type: "fetch_ecometrics" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchKPIDataInputs() {
      const bodyData = { action_type: "fetch_kpi_inputs" };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchWeights() {
      let bodyData = { action_type: "fetch_weights" };
      let criteria = {
        asset_summary_link_asset_uuid: this.record.econ_data_link_asset_uuid,
      };

      bodyData = { ...bodyData, ...criteria };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    async fetchAssetScores() {
      let bodyData = { action_type: "fetch_asset_scores" };
      let criteria = {
        econ_data_link_asset_uuid: this.record.econ_data_link_asset_uuid,
      };

      bodyData = { ...bodyData, ...criteria };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();
        return data;
      } catch (err) {
        console.log(err);
        return (this.error = "Failed to load, try later.");
      }
    },
    switchActivityStatus(type) {
      this.selectedRecord = false;
      this.editMode = false;
      this.selData = {};
      this.activeWeightsRecord = {};
      this.activeAssetScoreRecord = "";
      this.selectedSubMetric = false;
      //this.subMetricDefsFilteredRecords = {};
      for (let key in this.record) {
        if (
          key !== "econ_data_link_asset_uuid" &&
          key !== "econ_data_link_metric_def_id"
        ) {
          this.record[key] = null;
        }
      }
      this.activityStatus = type;
      this.fetchRecords().then((data) => {
        if (data[0]) {
          this.record = data[0];
        }
        this.fetchWeights().then((data_2) => {
          this.assetWeightsRecord = data_2[0];
        });
        this.fetchAssetScores().then((data_3) => {
          this.assetScoreRecord = data_3[0];
        });
        this.selectedRecord = true;
      });
    },
    editRecord() {
      this.editMode = true;
    },
    cancelEdit() {
      this.assetSelected();
      for (let key in this.record) {
        if (
          key !== "econ_data_link_asset_uuid" &&
          key !== "econ_data_link_metric_def_id"
        ) {
          this.record[key] = null;
        }
      }
    },
    resetForm() {
      this.record = {};
      this.selData = {};
      this.selectedAsset = false;
      this.selectedMetric = false;
      this.selectedSubMetric = false;
      this.selectedRecord = false;
      this.editMode = false;
      this.assetWeightsRecord = {};
      this.activeWeightsRecord = {};
      this.activeAssetScoreRecord = "";
    },
    async saveRecord() {
      /*

          this.isLoading = true;

          let getAuth = this.$store.getters["auth/prepAuthHeaders"](
            "fetch_ecosystems"
          );

          const bodyData = { ...getAuth };

          */

      let bodyData = { action_type: "save_econ_data" };

      let criteria = {
        econ_data_link_asset_uuid: this.record.econ_data_link_asset_uuid,
        econ_data_link_type_id: this.dataType,
        econ_data_activity_status: this.activityStatus,
      };

      bodyData = { ...bodyData, ...this.record, ...criteria };

      let url = this.$store.state.endpointUrl;

      try {
        //fetching data from api
        let response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });

        let data = await response.json();

        this.editMode = false;

        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: "Record has been Saved/Updated",
          life: 3000,
        });

        return data;
      } catch (err) {
        console.log(err);
        this.editMode = false;

        this.$toast.add({
          severity: "error",
          summary: "Error Occured",
          detail: "An error has occured. Please try again later.",
          life: 3000,
        });

        return (this.error = "Failed to load, try later.");
      }
    },
    assetSelected() {
      this.selectedAsset = true;
      this.editMode = false;
    },
    metricSelected() {
      this.selectedMetric = true;
      this.selData = {};
      this.activeWeightsRecord = {};
      this.activeAssetScoreRecord = "";
      for (let key in this.record) {
        if (
          key !== "econ_data_link_asset_uuid" &&
          key !== "econ_data_link_metric_def_id"
        ) {
          this.record[key] = null;
        }
      }

      let my_array = this.subMetricDefsRecords;

      let criteria = {
        define_sub_metric_defination_link_ecosystem_id: this.dataType,
        define_sub_metric_defination_link_metric_id:
          this.record.econ_data_link_metric_def_id,
      };

      console.log(my_array);
      console.log(criteria);

      let final_array = this.$store.getters["common/filterObjects"](
        my_array,
        criteria
      );
      console.log(final_array);
      this.subMetricDefsFilteredRecords = final_array;
    },
    subMetricSelected() {
      let key = "uuid";
      let valueToFind = this.record.econ_data_link_sub_metric_def_uuid;

      this.selectedSubMetric = true;

      let obj = this.subMetricDefsRecords.find(
        (item) => item[key] === valueToFind
      );
      this.selData.metric_rationale =
        obj.define_sub_metric_defination_rationale;

      for (let key in this.record) {
        if (
          key !== "econ_data_link_asset_uuid" &&
          key !== "econ_data_link_metric_def_id" &&
          key !== "econ_data_link_sub_metric_def_uuid"
        ) {
          this.record[key] = null;
        }
      }

      this.fetchRecords().then((data) => {
        if (data[0]) {
          this.record = data[0];

          this.fetchWeights().then((data_2) => {
            this.assetWeightsRecord = data_2[0];

            this.fetchAssetScores().then((data_3) => {
              this.assetScoreRecord = data_3[0];
              this.getWeightedValue();
            });
          });
        }
        this.selectedRecord = true;
      });
    },
    getScoreKeyField(num) {
      if (num == 0) {
        return "define_scores_zero";
      } else if (num == 1) {
        return "define_scores_one";
      } else if (num == 2) {
        return "define_scores_two";
      } else if (num == 3) {
        return "define_scores_three";
      } else if (num == 4) {
        return "define_scores_four";
      } else if (num == 5) {
        return "define_scores_five";
      } else {
        return null;
      }
    },
    findObjectAndReturnValue(
      arrayToSearch,
      keyToMatch,
      valueToMatch,
      keyToReturn
    ) {
      let obj = arrayToSearch.find((item) => item[keyToMatch] === valueToMatch);

      if (obj && keyToReturn in obj) {
        return obj[keyToReturn];
      } else {
        // Return some default value or throw an error if the object or key was not found
        return "";
      }
    },
    updateN_forest(newValue) {
      this.record.econ_data_natural_forest = Number(newValue);

      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.n_forest = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    updatePlantation(newValue) {
      this.record.econ_data_plantation = newValue;

      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.plantation = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    updateAgriculture(newValue) {
      this.record.econ_data_agriculture = newValue;

      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.agriculture = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    updateSavannah(newValue) {
      this.record.econ_data_savannah = newValue;
      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.savannah = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    updateConservation(newValue) {
      this.record.econ_data_conservation_arc = newValue;

      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.conservation = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    updateMangrove(newValue) {
      this.record.econ_data_mangrove = newValue;
      let keyToMatch = "define_scores_link_sub_metric_uuid";
      let keyToReturn = this.getScoreKeyField(newValue);
      let valueToMatch = this.record.econ_data_link_sub_metric_def_uuid;

      this.selData.mangrove = this.findObjectAndReturnValue(
        this.scoresRecords,
        keyToMatch,
        valueToMatch,
        keyToReturn
      );
    },
    getWeightedValue() {
      /**
       * n_forest
       * plantation
       * agriculture
       * savannah
       * conservation
       * mangrove
       * assetWeightsRecord
       * activeWeightsRecord
       */

      if (this.activityStatus == 1) {
        this.activeWeightsRecord.n_forest =
          this.assetWeightsRecord.asset_summary_current_n_forest_weight;
        this.activeWeightsRecord.plantation =
          this.assetWeightsRecord.asset_summary_current_plantation_weight;
        this.activeWeightsRecord.agriculture =
          this.assetWeightsRecord.asset_summary_current_agriculture_weight;
        this.activeWeightsRecord.savannah =
          this.assetWeightsRecord.asset_summary_current_savannah_weight;
        this.activeWeightsRecord.conservation =
          this.assetWeightsRecord.asset_summary_conservation_forest_weight;
        this.activeWeightsRecord.mangrove =
          this.assetWeightsRecord.asset_summary_current_mangrove_weight;

        this.activeAssetScoreRecord = this.assetScoreRecord.asset_score_current;
      } else if (this.activityStatus == 2) {
        this.activeWeightsRecord.n_forest =
          this.assetWeightsRecord.asset_summary_planned_n_forest_weight;
        this.activeWeightsRecord.plantation =
          this.assetWeightsRecord.asset_summary_planned_plantation_weight;
        this.activeWeightsRecord.agriculture =
          this.assetWeightsRecord.asset_summary_planned_agriculture_weight;
        this.activeWeightsRecord.savannah =
          this.assetWeightsRecord.asset_summary_planned_savannah_weight;
        this.activeWeightsRecord.conservation =
          this.assetWeightsRecord.asset_summary_planned_conservation_weight;
        this.activeWeightsRecord.mangrove =
          this.assetWeightsRecord.asset_summary_planned_mangrove_weight;

        this.activeAssetScoreRecord = this.assetScoreRecord.asset_score_planned;
      } else if (this.activityStatus == 3) {
        this.activeWeightsRecord.n_forest =
          this.assetWeightsRecord.asset_summary_unplanned_n_forest_weight;
        this.activeWeightsRecord.plantation =
          this.assetWeightsRecord.asset_summary_unplanned_plantation_weight;
        this.activeWeightsRecord.agriculture =
          this.assetWeightsRecord.asset_summary_unplanned_agriculture_weight;
        this.activeWeightsRecord.savannah =
          this.assetWeightsRecord.asset_summary_unplanned_savannah_weight;
        this.activeWeightsRecord.conservation =
          this.assetWeightsRecord.asset_summary_unplanned_conservation_weight;
        this.activeWeightsRecord.mangrove =
          this.assetWeightsRecord.asset_summary_unplanned_mangrove_weight;

        this.activeAssetScoreRecord =
          this.assetScoreRecord.asset_score_unplanned;
      } else {
        return "";
      }
    },
    allowedRoles(my_arguments) {
      const my_array = Array.from(my_arguments);
      /* console.log("allowedRoles",my_arguments);
      console.log("allowedRoles - ID ",this.userRoleID);
      console.log("return - ID ",my_array.includes(Number(this.userRoleID)) ? true : false); */
      return my_array.includes(Number(this.userRoleID)) ? true : false;
    },
  },
};
</script>
