<template>
  <br />
  <br />
  <div
    align="center"
    class="card flex align-items-center justify-content-center"
  >
    <pv-card style="width: 25em">
      <template #content>
        <h4>Ooops! The page you are looking for cannot be found.</h4>
      </template>
      <template #footer>
        <router-link to="/"
          ><pv-button icon="pi pi-home" label="Go to Home Page" link to="/"
        /></router-link>
      </template>
    </pv-card>
  </div>
</template>
