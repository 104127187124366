<template> 
  <div class="flex justify-content-center flex-wrap pt-8">
    <pv-toast />
    <div class="content-card">
      <div class="grid">
        <div class="col-12">
          <div class="field grid">
            <label
              for="username"
              class="col-12 mb-2 md:col-4 md:mb-0 sm:col-12 pb-2"
              >Username</label
            >
            <div class="col-12 md:col-10">
              <pv-inputtext id="username" type="text" v-model.trim="username" />
            </div>
          </div>
          <div class="field grid">
            <label
              for="password"
              class="col-12 mb-2 md:col-8 md:mb-0 sm:col-12 pb-2"
              >Password</label
            >
            <div class="col-12">
              <pv-inputtext
                id="password"
                type="password"
                v-model.trim="password"
              />
            </div>
          </div>
          <div class="field grid">
            <div class="col-12 md:col-12 py-1">
              <pv-button
                label="Login"
                icon="fa-solid fa-right-to-bracket"
                size="large"
                iconPos="right"
                :loading="isLoading"
                @click="submitForm"
              ></pv-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
    };
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === "login") {
        return "Login";
      } else {
        return "Signup";
      }
    },
    switchModeButtonCaption() {
      if (this.mode === "auth") {
        return "Signup instead";
      } else {
        return "Login instead";
      }
    },
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      /*       if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      } */

      this.isLoading = true;

      const actionPayload = {
        username: this.username,
        password: this.password,
      };

      //console.log(actionPayload);

      try {
        if (this.mode === "login") {
          await this.$store.dispatch("auth/login", actionPayload);
        } else {
          await this.$store.dispatch("auth/signup", actionPayload);
        }
        const redirectUrl = "/" + (this.$route.query.redirect || "");
        this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
        //console.log(err.message);
        this.$toast.add({
          severity: "error",
          summary: "Authentication Failed.",
          detail: err.message,
          life: 3000,
        });
      }

      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === "auth") {
        this.mode = "signup";
      } else {
        this.mode = "auth";
      }
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>
