export const dashboard_menu = [
  {
    header: "Dashboard",
    hiddenOnCollapse: true,
  },
  {
    href: "/",
    title: "Insights",
    icon: "fa-solid fa-chart-line",
  },
  {
    href: "/ertables",
    title: "eR Tables",
    icon: "fa-solid fa-table-list",
  },
];

export const econ_menu = [
  {
    header: "Ecosystem Condition",
    hiddenOnCollapse: true,
  },
  {
    href: "/vegetation",
    title: "Vegetation (Flora)",
    icon: "fa-solid fa-tree",
  },
  {
    href: "/biodiversity",
    title: "Biodiversity (Fauna) ",
    icon: "fa-solid fa-kiwi-bird",
  },
  {
    href: "/water",
    title: "Water",
    icon: "fa-solid fa-hand-holding-droplet",
  },
  {
    href: "/carbon",
    title: "Carbon",
    icon: "fa-solid fa-wind",
  },
  {
    href: "/social",
    title: "Social",
    icon: "fa-solid fa-people-group",
  },
  {
    href: "/assetsummary",
    title: "Asset Summary",
    icon: "fa-solid fa-landmark-flag",
  },
];

export const settings_menu = [
  {
    header: "Settings",
    hiddenOnCollapse: true,
  },
  {
    href: "/defassets",
    title: "Define Assets",
    icon: "fa-solid fa-arrows-to-eye",
  },
  {
    href: "/definputs",
    title: "Define Inputs",
    icon: "a-solid fa-wrench",
  },
  {
    href: "/defmetrics",
    title: "Define Metrics",
    icon: "fa-solid fa-bars-progress",
  },
  {
    href: "/defscores",
    title: "Define Scores",
    icon: "fa-solid fa-list-check",
  },
];

export const security_menu = [
  {
    header: "Security",
    hiddenOnCollapse: true,
  },
  {
    href: "/authmanager",
    title: "User Manager",
    icon: "fa-solid fa-building-lock",
  },
];
