export default {
  setUser(state, payload) {
    //console.log(payload);
    state.userId = payload.userId;
    state.roleId = payload.roleId;
    state.fullname = payload.fullname;
    state.authToken = payload.authToken;
    state.didAutoLogout = false;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
};
