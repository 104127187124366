<template>
  <div style="height: 600px; width: 800px">
    <l-map ref="map" v-model:zoom="zoom" :center="mapCenter">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-polygon :latLngs="vectors"></l-polygon>
    </l-map>
  </div>
</template>

<!-- https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson -->

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LPolygon } from "@vue-leaflet/vue-leaflet";

export default {
  props: {
    vectors: {
      type: Array,
      default: () => [], // sets default value to an empty array
    },
  },
  components: {
    LMap,
    LTileLayer,
    LPolygon,
  },
  computed: {
    mapCenter() {
      console.log(this.vectors);
      if (this.vectors) {
        let latitude = 0;
        let longitude = 0;
        let coords = this.vectors;
        const numPoints = coords[0].length;

        let invertedCoords = coords.map((middle) => {
          return middle.map((inner) => {
            return [inner[1], inner[0]];
          });
        });

        for (let i = 0; i < numPoints; i++) {
          latitude += invertedCoords[0][i][1];
          longitude += invertedCoords[0][i][0];
        }

        return [latitude / numPoints, longitude / numPoints];
      } else {
        return [0, 0];
      }
    },
  },
  data() {
    return {
      zoom: 6,
    };
  },
};
</script>
